<template>
    <ul class="marketInfo">
        <li class="marketInfoLi">
            <span class="marketInfoKey">市场名称</span>
            <span class="marketInfoVal">{{storeInfo.marketName}}</span>
        </li>
        <li class="marketInfoLi">
            <span class="marketInfoKey">市场地址</span>
            <span class="marketInfoVal">{{storeInfo.storeAddr}}</span>
        </li>
        <li class="marketInfoLi">
            <span class="marketInfoKey">联系人</span>
            <span class="marketInfoVal">{{storeInfo.storeShopkeeper}}</span>
        </li>
        <li class="marketInfoLi">
            <span class="marketInfoKey">联系电话</span>
            <span class="marketInfoVal">{{storeInfo.storeShopkeeperTel}}</span>
        </li>
        <li class="marketInfoLi">
            <span class="marketInfoKey">经营范围</span>
            <span class="marketInfoVal" v-if="businessScopeWord">{{businessScopeWord}}</span>
            <div class="marketInfoVal" v-if="businessScope.length">
                <span class="businessScope" v-for="(item, index) in businessScope" :key="index">{{item}}</span>
            </div>
        </li>
        <li class="marketInfoLi">
            <span class="marketInfoKey">商铺类别</span>
            <span class="marketInfoVal">农贸市场</span>
        </li>
        <li class="marketInfoLi">
            <span class="marketInfoKey">配送服务</span>
            <span class="marketInfoVal">{{delivery}}</span>
        </li>
    </ul>
</template>

<script>
    export default {
        name: "marketInfo",
        data() {
            return {
                delivery: '无',
                deliveryOptions: ['有', '无'],
                businessScope: [],
                businessScopeWord: ''
            }
        },
        mounted() {
        },
        props: {
            storeInfo: Object
        },
        watch: {
            storeInfo: function(val){
                if (val.storeCategory[0] === '['){
                    for (let item of JSON.parse(val.storeCategory)){
                        console.log(item);
                        this.businessScope.push(item);
                    }
                }else{
                    this.businessScopeWord = val.storeCategory;
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    .marketInfo{
        width: 700px;
        margin: 50px auto 0;
        padding: 0;
        .marketInfoTitle{
            font-size: 36px/*px*/;
            color: #B2B2B2;
            text-align: left;
            margin: 0;
            padding: 0;
        }
        .marketInfoLi{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #E5E5E5;
            font-size: 30px/*px*/;
            height: 90px;
            padding: 0 20px;
            .marketInfoVal{
                color: #B2B2B2;
                display: flex;
                .businessScope{
                    display: block;
                    padding: 0 10px;
                    text-align: right;
                }
            }
        }
    }
</style>
