<template>
  <header-box :storeInfo="storeInfo"></header-box>
  <ul class="certificateBox">
    <p class="no-history" v-if="historyRecords.length === 0">无历史记录</p>
    <li v-else v-for="(item, index) in historyRecords" :key="index">
      <div class="certificateInfo food-safety">
        <p class="certificateName">
          <span>{{parseTime(item.createTime)}}上传</span>
        </p>
      </div>
      <div>
        <img v-for="(imgItem, imgIndex) in JSON.parse(item.imgs)" :key="imgIndex" :src="imgItem" />
      </div>
    </li>
  </ul>
</template>

<script>
  import axios from "axios";
  import headerBox from "../components/headerBox";

  export default {
    name: "FoodSafetyHistory",
    components: {
      headerBox,
    },
    data() {
      return {
        storeInfo: {},
        historyRecords: [],
      }
    },
    methods: {
      parseTime(timestamp){
        let date = new Date(timestamp);

        let year = date.getFullYear();
        let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        let hour = date.getHours() < 10 ? '0' + date.getHours(): date.getHours();
        let minute = date.getMinutes() < 10 ? '0' + date.getMinutes(): date.getMinutes();
        let second = date.getSeconds() < 10 ? '0' + date.getSeconds(): date.getSeconds();

        return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      },
    },
    mounted() {
      this.storeInfo = this.$store.state.storeInfo;
      // console.log(this.storeInfo)
      axios({
        url: '/api/Store/getHistoryFoodSafety?storeId=' + this.storeInfo.id,
        method: 'get',
        contentType: 'application/x-www-form-urlencoded',
        // data: this.storeInfo.id,
      }).then(res => {
        this.historyRecords = JSON.parse(res.data.data);
        console.log(this.historyRecords);
      })
    }
  }
</script>

<style scoped lang="scss">
  .certificateBox{
    background: #F1F1F1;
    padding: 25px;
    list-style: none;
    margin: 0;
    min-height: 87vh;

    .no-history{
      height: 100%;
    }

    li{
      padding: 30px;
      background: #fff;
      border-radius: 10px;
      .certificateInfo{
        margin-bottom: 20px;
        text-align: left;
        display: flex;
        justify-content: space-between;
        p{
          margin: 0;
        }
        .certificateName{
          font-size: 32px/*px*/;
          color: #333;
        }

        &.food-safety{
          .certificateName{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
      img{
        width: 100%;
        border-radius: 10px;
        border: 1px/*no*/ solid #eee;
      }
      .noImg{
        display: inline-block;
        font-size: 28px;
        color: #999;
      }
    }
    li{
      margin-bottom: 25px;
    }
    li:last-of-type{
      margin-bottom: 0;
    }
  }
</style>
