//页面跳转
function goToPage(url, param, store) {
    store.commit('goToPage', {url, param})
}

//时间解析
function formatDate(date) {
    let y = date.getFullYear();

    let m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;

    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;

    return y + '-' + m + '-' + d;
}

export default {
    goToPage,
    formatDate
}
