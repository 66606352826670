<template>
  <header-box :storeInfo="storeInfo"></header-box>
  <el-alert
      :title="alertTitle"
      type="warning"
      center="true"
      :closable="false"
      v-if="alertShow">
  </el-alert>
  <el-empty description="暂无数据" v-if="!haveData"></el-empty>
  <div class="traceabilityList" v-if="haveData">
    <!--        <ul class="categoryList">-->
    <!--            <li v-for="(item, index) in categoryList"-->
    <!--                :key="index"-->
    <!--                :class="index === this.activeIndex ? 'active' : ''"-->
    <!--                @click="changeCategoryIndex(item, index)"-->
    <!--            >{{item.goodsName}}</li>-->
    <!--        </ul>-->
    <ul class="categoryTraceabilityList" ref="container" @scroll="handleScroll">
      <li v-for="(item, index) in traceabilityList" :key="index"
          @click="goToPage('TraceabilityDetail', {traceability: JSON.stringify(item)})">
        <!--                <div class="imgBox">-->
        <!--&lt;!&ndash;                    <img :src="item.traceability.goodsImg" alt="">&ndash;&gt;-->
        <!--                </div>-->
        <div class="traceabilityInfo">
          <div class="foodNameAndPrice">
            <p class="foodName">{{item.goodsName}}</p>
            <div class="foodPrice"><span class="priceText">{{item.price.toFixed(2)}}</span> 元/斤</div>
          </div>
          <p class="traceabilityTime">进货时间：<span>{{formatDate(item.date)}}</span></p>
          <p class="foodSource">供货商：<span>{{item.goodsOwner}}</span></p>
        </div>
        <div class="detailIcon">
          <i class="el-icon-arrow-right"></i>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import headerBox from "../components/headerBox";
  import globalFunctions from "../assets/js/globalFunctions";
  import axios from 'axios';

  export default {
    name: "TraceabilityList",
    components: {
      headerBox
    },
    data() {
      return {
        storeInfo: {},
        //溯源数据商品分类数组
        categoryList: [],
        //当前选中商品分类index
        activeIndex: 0,
        //当前选中商品分类对象
        categoryItem: {},
        //总页数
        totalPage: 0,
        //当前已显示的页数
        currentPage: 1,
        //全部商品溯源数据数组
        allTraceabilityList: [],
        //显示的商品溯源数据数组
        traceabilityList: [],
        //是否有溯源数据flag
        haveData: true,
        //未上传数据的天数
        alertTitle: 0,
        alertShow: false,
        displayComplete: false,
      }
    },
    methods: {
      goToPage(url, param) {
        globalFunctions.goToPage(url, param, this.$store)
      },

      //改变商品分类方法
      // changeCategoryIndex(item, index) {
      //     this.categoryItem = item;
      //     this.activeIndex = index;
      //     this.getTraceabilityList(this.storeInfo.id, this.categoryItem.id);
      // },

      formatDate(date) {
        return globalFunctions.formatDate(new Date(date));
      },
      //发送请求获取选中商品分类溯源数据
      getTraceabilityList(storeId, pageNum) {
        // console.log(pageNum)
        axios({
          url: `/api/getAllGoodsTracesByStoreId?storeId=${storeId}&pageSize=10&pageNum=${pageNum}`,
          contentType: 'application/json',
          dataType: 'json',
        }).then(res => {
          if (res.status === 200 && res.data.success){
            console.log(JSON.parse(res.data.data));
            for (let item of JSON.parse(res.data.data)){
              this.traceabilityList.push(item);
            }
          }else{
            this.currentPage--;
          }
          // console.log(this.traceabilityList)
          //溯源数据按时间降序排列
          // this.allTraceabilityList = JSON.parse(res.data.data).sort((a, b) => {
          //   return b.date - a.date;
          // });
          // this.totalPage = Math.floor(this.allTraceabilityList.length / 10);
          // this.addDataToTraceabilityList();
        })
      },
      handleScroll() {
        const container = this.$refs.container
        const scrollHeight = container.scrollHeight
        const scrollTop = container.scrollTop
        const clientHeight = container.clientHeight
        if (scrollTop + clientHeight >= scrollHeight) {
          this.getTraceabilityList(this.storeInfo.id, ++this.currentPage);
        }
      },
      // addDataToTraceabilityList(){
      //   if (!this.displayComplete) {
      //     if (this.currentPage < this.totalPage) {
      //       for (let i = (this.currentPage - 1) * 10; (this.currentPage - 1) * 10 <= i && i < this.currentPage * 10; i++) {
      //         this.traceabilityList.push(this.allTraceabilityList[i]);
      //       }
      //       this.currentPage++;
      //     } else {
      //       for (let i = (this.currentPage - 1) * 10; (this.currentPage - 1) * 10 <= i && i < this.allTraceabilityList.length; i++) {
      //         this.traceabilityList.push(this.allTraceabilityList[i]);
      //       }
      //       this.displayComplete = true;
      //     }
      //   }
      // }
    },
    mounted() {
      this.storeInfo = this.$store.state.storeInfo;
      let storeId = this.storeInfo.id;

      new Promise((resolve, reject) => {
        //通过商家Id获取可查询的溯源商品分类
        axios({
          url: `/api/getAllGoodsKindsByStoreId?id=${storeId}`,
          contentType: 'application/json',
          dataType: 'json'
        }).then(res => {
          //没有溯源数据页面显示空状态
          console.log(JSON.parse(res.data.data));
          if (!JSON.parse(res.data.data)) {
            this.haveData = false;
            // if (JSON.parse(res.data.data) !== null) {
            //   if (JSON.parse(res.data.data).noDataDays >= 15) {
            //     this.alertTitle = "近15天内未上传溯源数据！";
            //   }
            //     // else if(JSON.parse(res.data.data).noDataDays >= 3 && JSON.parse(res.data.data).noDataDays < 15){
            //     //     this.alertTitle = "近" + JSON.parse(res.data.data).noDataDays + "天内未上传溯源数据！";
            //   // }
            //   else {
            //     this.alertShow = false;
            //   }
            // } else {
            //   this.alertTitle = "近15天内未上传溯源数据！";
            // }
          } else {
            this.categoryList = JSON.parse(res.data.data).simples;
            // console.log(this.categoryList);
            // this.alertShow = false;
            resolve();
          }
        })
      }).then(() => {
        this.getTraceabilityList(this.storeInfo.id, this.currentPage);
      })
    }
  }
</script>

<style scoped lang="scss">
  .traceabilityList {
    background: #F1F1F1;
    height: calc(100vh - 160px);
    display: flex;

    ul {
      margin: 0;
      list-style: none;

      li {
        background: #fff;
        display: flex;
        border-bottom: 1px solid #F1F1F1;
      }
    }

    .categoryList {
      padding: 0;
      width: 20%;
      height: 100%;
      border-right: 1px solid #F1F1F1;
      color: #999;
      font-size: 28px /*px*/
    ;
      overflow: auto;

      li {
        border-radius: 20% 0 0 20%;
        height: 120px;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }

      .active {
        background: #2DA277;
        color: #fff;
      }
    }

    .categoryTraceabilityList {
      height: calc(100vh - 160px);
      overflow: auto;
      /*width: 80%;*/
      width: 100%;
      padding: 0 20px;

      li {
        height: 180px;
        padding: 20px 0;
        box-sizing: border-box;
        margin-top: 20px;
        align-items: center;
        border-radius: 10px;
        position: relative;

        .imgBox {
          width: 20%;
          height: 100%;
          margin: 0 30px 0 20px;
          display: flex;
          align-items: center;
          box-shadow: 0 0 5px 1px #eee;
          border-radius: 8%;
        }

        img {
          width: 100%;
        }

        .traceabilityInfo {
          padding: 0 30px;
          height: 120px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;

          p {
            margin: 0;
            font-size: 28px;
            color: #999;
            text-align: left;

            span {
              color: #ccc;
            }
          }

          .foodNameAndPrice {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .foodName {
              font-size: 36px /*px*/
            ;
              color: #333;
              width: 50%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .foodPrice {
              .priceText {
                color: #ff4628;
                font-size: 36px /*px*/
              ;
              }
            }
          }

          .foodSource {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .detailIcon {
          display: flex;
          align-items: end;
          justify-content: center;
          padding-bottom: 50px;
          height: 100%;
          font-size: 40px;
          position: absolute;
          right: 10px;
          color: #999;
        }
      }
    }
  }

  .el-empty {
    background: #fff;
    padding: 300px 0;
  }
</style>
