<template>
    <div class="headBg"></div>
    <div class="storeTitle">
        <el-button class="backBtn" type="primary" icon="el-icon-arrow-left" @click="backPage"></el-button>
        <img src="../assets/img/组35.png" alt="">
        <div class="nameAndRate">
            <p>{{storeInfo.storeName}}</p>
            <div class="rateAndBtn">
                <el-rate
                        class="rateBox"
                        void-color="#F1F1F1"
                        v-model="storeRateValue"
                        disabled
                        allow-half
                ></el-rate>
                <button class="rateBtn el-icon-edit" @click="drawer = true"></button>
<!--                <el-button round class="rateBtn" @click="drawer = false">评价</el-button>-->
            </div>
        </div>
    </div>
    <el-drawer
            custom-class="drawerBox"
            v-model="drawer"
            direction="btt"
            size="30%"
            :destroy-on-close="true"
            :with-header="false"
    >
      <p class="drawerTitle">— 总体评价 —</p>
        <el-rate
                class="rateBox"
                v-model="userRate"
                allow-half
        ></el-rate>
        <el-form size="mini">
<!--            <el-form-item>-->
<!--                <el-input-->
<!--                        v-model="opinion"-->
<!--                        type="textarea"-->
<!--                        rows="3"-->
<!--                        resize="none"-->
<!--                        placeholder="提出您宝贵的意见吧~"-->
<!--                ></el-input>-->
<!--            </el-form-item>-->
            <el-button type="primary" @click="submitComment">提交评分</el-button>
        </el-form>
    </el-drawer>
</template>

<script>
    import { ElNotification } from 'element-plus'
    import axios from "axios";

    export default {
        name: "headerBox",
        data() {
            return {
                storeRateValue: 0,
                userRate: '',
                drawer: false,
                opinion: '',
                storeInfoData: '',
            }
        },
        props: {
            storeInfo: Object,
        },
        methods: {
            submitComment() {
                // this.opinion = '';
                // this.userRate = 0;
                this.drawer = false;

              // console.log(this.userRate);
              let evaluation = {
                "storeId":this.$store.state.storeInfo.id,
                "evaluationScore":this.userRate
              }
              axios({
                url: '/api/createEvaluate',
                method: 'post',
                contentType: 'application/json',
                dataType:'json',
                data: evaluation
              }).then(res => {
                // console.log(res);
                this.commentSuccess();
              })
            },
            commentSuccess() {
                ElNotification ({
                    title: '操作成功！',
                    message: '感谢您帮助我们进步！',
                    type: 'success',
                    showClose: false,
                    duration: 2000
                });
              setTimeout(function(){location.reload();},1000);


            },
            backPage() {
                // this.$router.back();
                this.$router.go(-1);
            },
            // getRate() {
            //     axios({
            //         url: '/getStoreEvaluate',
            //         method: 'post',
            //         contentType: 'application/x-www-form-urlencoded',
            //         data: {storeId:this.$store.state.storeInfo.id}
            //     }).then(res => {
            //         console.log(res);
            //     })
            // }

        },

        watch: {
            storeInfo: function (newVal, oldVal){
                // this.storeInfoData = newVal;
                // console.log(this.storeInfoData);
                axios({
                  url: '/api/getStoreEvaluate',
                  method: 'post',
                  contentType: 'application/x-www-form-urlencoded',
                  // data: {storeId:newVal.id}
                  data: `storeId=${newVal.id}`
                }).then(res => {
                  // console.log(res);
                  this.storeRateValue = res.data.data;
                })

            }
        }
    }
</script>

<style scoped lang="scss">
    .headBg{
        width: 100%;
        height: 400px;
        border-radius: 30px;
        background: linear-gradient(134deg, #42B88D 0%, #1D9F70 100%);
        position: absolute;
        top: -100px;
        z-index: -1;
    }
    .storeTitle{
        display: flex;
        align-items: center;
        width: 700px;
        height: 100px;
        margin: 30px auto;
        padding-right: 20px;
        .backBtn{
            font-size: 50px;
            background: transparent;
            border: none;
            padding: 0;
        }
        img{
            width: 80px;
            height: 80px;
            margin-left: 20px;
        }
        .nameAndRate {
            width: 70%;
            text-align: left;
            p {
                font-size: 40px /*px*/;
                margin: -5px 30px 0px;
                color: #fff;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .rateAndBtn{
            display: flex;
            align-items: center;
            position: relative;
            .rateBox{
                text-align: left;
                margin-left: 30px;
            }
            .rateBtn{
                height: 50px;
                width: 50px;
                color: #fff;
                display: flex;
                align-items: center;
                border-radius: 20px;
                border: none;
                position: absolute;
                left: 50%;
                background: transparent;
                font-size: 36px/*px*/;
                text-align: left;
                padding: 0;
                margin: 0;
                outline: none;
            }
        }
    }
    .drawerBox {
        .rateBox{
            text-align: center;
            margin: 0 0 40px 0;
        }
        .header {
            margin-bottom: 0!important;
        }
    }
    .rateBox{
        --el-rate-icon-size: 40px;
        --el-rate-icon-margin: 0;
    }
    .drawerTitle{
      font-size: 0.4rem
    }
</style>
