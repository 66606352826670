<template>
    <header-box :storeInfo="storeInfo"></header-box>
    <div class="backgroundBox">
        <div class="info">
<!--            <div class="goodImgBox">-->
<!--                <img :src="foodImg" alt="">-->
<!--            </div>-->
            <ul>
                <li>
                    <span>品名</span>
                    <span>{{foodName}}</span>
                </li>
                <li>
                    <span>供货商</span>
                    <span>{{traceabilityInfo.goodsOwner}}</span>
                </li>
                <li>
                    <span>产地</span>
                    <span>{{traceabilityInfo.location}}</span>
                </li>
                <li>
                    <span>进货时间</span>
                    <span>{{formatDate(new Date(traceabilityInfo.date))}}</span>
                </li>
                <li>
                    <span>进货数量(kg/件/箱)</span>
                    <span>{{traceabilityInfo.weight}}</span>
                </li>
            </ul>
        </div>
        <ul class="imgBox">
            <li>
                <p>进货凭证</p>
                <img v-if="showFlagList.traceImg" :src="traceabilityInfo.traceImg" alt="">
                <span v-if="!showFlagList.traceImg" class="noImg">暂无</span>
            </li>
            <li>
                <p>合格证</p>
                <img v-if="showFlagList.certificate" :src="traceabilityInfo.certificate" alt="">
                <span v-if="!showFlagList.certificate" class="noImg">暂无</span>
            </li>
            <li>
                <p>检疫证明</p>
                <img v-if="showFlagList.quarantine" :src="traceabilityInfo.quarantine" alt="">
                <span v-if="!showFlagList.quarantine" class="noImg">暂无</span>
            </li>
            <li>
                <p>新冠检测证明</p>
                <img v-if="showFlagList.covidTest" :src="traceabilityInfo.covidTest" alt="">
                <span v-if="!showFlagList.covidTest" class="noImg">暂无</span>
            </li>
        </ul>
    </div>
</template>

<script>
    import headerBox from "../components/headerBox";
    import globalFunctions from "../assets/js/globalFunctions";

    export default {
        name: "TraceabilityDetail",
        components: {
            headerBox
        },
        data() {
            return {
                foodImg: '',
                foodName: '',
                storeInfo: {},
                traceabilityInfo: {},
                showFlagList: {
                    traceImg: false,
                    certificate: false,
                    quarantine: false,
                    covidTest: false,
                },
            }
        },
        mounted() {
            this.storeInfo = this.$store.state.storeInfo;
            //获取通过vue-router跳转传递的参数
            this.traceabilityInfo = JSON.parse(this.$route.query.traceability);
            this.foodImg = this.traceabilityInfo.goodsImg;
            this.foodName = this.traceabilityInfo.goodsName;
            for (let i = 0; i <  Object.keys(this.showFlagList).length; i++){
                let key = Object.keys(this.showFlagList)[i];
                if (this.traceabilityInfo[key] && this.traceabilityInfo[key] !== 'http://m.wisevege.com/foodImg/zanwu.png'){
                    this.showFlagList[key] = true;
                }else{
                    this.showFlagList[key] = false;
                }
            }
        },
        methods: {
            formatDate(date) {
                return globalFunctions.formatDate(date)
            },
        }
    }
</script>

<style scoped lang="scss">
    .backgroundBox{
        background: #F1F1F1;
        ul{
            margin: 0;
            padding: 0;
            list-style: none;
        }
        .info{
            background: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 25px;
            box-sizing: border-box;
            box-shadow: 0 1px 20px 0 #999;
            position: relative;
            .goodImgBox{
                width: 250px;
                height: 250px;
                display: flex;
                align-items: center;
                border-radius: 8%;
                box-shadow: 0 0 20px 1px #eee;
                img{
                    width: 100%;
                }
            }
            ul{
                width: 100%;
                display: flex;
                flex-direction: column;
                /*height: 250px;*/
                li{
                    padding: 10px 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #E5E5E5;
                    height: 20%;
                    font-size: 28px;
                    span:last-of-type{
                        display: -webkit-box;
                        color: #B2B2B2;
                        max-width: 70%;
                        text-align: right;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                    }
                }
            }
        }
        .imgBox{
            padding: 0 25px 25px;
            overflow: auto;
            height: calc(100vh - 500px);
            li{
                background: #fff;
                padding: 0 25px;
                border-radius: 10px;
                p{
                    text-align: left;
                    font-size: 32px;
                    font-weight: bold;
                    padding-top: 25px;
                }
                img{
                    width: 100%;
                    border-radius: 10px;
                    margin: 0 0 40px;
                    border: 1px/*no*/ solid #eee;
                }
                .noImg{
                    display: inline-block;
                    margin: 0 0 40px;
                    font-size: 28px;
                    color: #999;
                }
            }
        }
    }
</style>
