<template>
    <header-box :storeInfo="storeInfo"></header-box>
    <div class="contentList">
        <div class="whiteBg">
            <div class="latestNews">
                <img src="http://www.cdqingyang.gov.cn/qyscjljgj/bmdt/2021-08/17/7cc78ea8be7247cd955886ae2aa8317b/images/b62cd3fd07ec4c32a13b601e837cec7a.png" alt="">
                <p>康河市场监管所开展疫情期间冷链食品防控工作</p>
            </div>
            <ul>
                <li>成都市青羊区持续开展“食安大讲堂”进社区系列活动</li>
                <li>成都市青羊区监督管理局食品安全信息公示栏制作项目比选公告</li>
                <li>齐心协力抗疫情 食品快检保安全</li>
                <li>2021年度规范化所建设采购公告</li>
            </ul>
        </div>
        <div class="whiteBg">
            <div class="latestNews">
                <img src="http://ymtzjt.com/Uploadfiles/Picture/2020-2-12/20202121825216874.png" alt="">
                <p>康河市场监管所开展疫情期间冷链食品防控工作</p>
            </div>
            <ul>
                <li>成都市青羊区持续开展“食安大讲堂”进社区系列活动</li>
                <li>成都市青羊区监督管理局食品安全信息公示栏制作项目比选公告</li>
                <li>齐心协力抗疫情 食品快检保安全</li>
                <li>2021年度规范化所建设采购公告</li>
            </ul>
        </div>
    </div>
</template>

<script>
    import headerBox from '../components/headerBox';
    import axios from 'axios';
    import globalFunctions from '../assets/js/globalFunctions'

    export default {
        name: "Supervision",
        components: {
            headerBox,
        },
        data() {
            return {
                storeInfo: []
            }
        },
        mounted() {
            this.storeInfo = this.$store.state.storeInfo;
        }
    }
</script>

<style scoped lang="scss">
    .contentList{
        background: #F1F1F1;
        min-height: calc(100vh - 160px);
        padding: 30px;
        font-size: 28px;
        color: #666;
        .whiteBg{
            border-radius: 10px;
            background: #fff;
            margin-bottom: 30px;
            .latestNews{
                padding: 30px;
                border-bottom: 2px/*no*/ solid #F1F1F1;
                img{
                    width: 100%;
                    border-radius: 10px;
                    box-shadow: 0 0 10px 1px #999;
                }
                p{
                    text-align: left;
                    margin-bottom: 0;
                }
            }
            ul{
                list-style: none;
                padding: 0;
                margin: 0;
                li{
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    border-bottom: 2px/*no*/ solid #F1F1F1;
                    padding: 30px;
                    box-sizing: border-box;
                    text-align: left;
                    &:last-of-type{
                        border-bottom: none;
                    }
                }
            }
        }
    }
</style>
