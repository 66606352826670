<template>
    <headerBox :storeInfo="storeInfo"></headerBox>
    <market-info :storeInfo="storeInfo"></market-info>
    <ul class="certificateBox">
<!--        <li v-if="this.healthCertificate">-->
        <li>
            <div class="certificateInfo">
                <p class="certificateName">店主健康体检报告</p>
            </div>
            <div class="certificateInfo">
                <span class="numAndPassTitle">报告编号</span>
                <span class="numAndPassVal">{{this.healthCertificate.healthCertificateImage ? this.healthCertificate.healthCertificateCode : '未录入'}}</span>
            </div>
            <div class="certificateInfo">
                <span class="numAndPassTitle">是否过期</span>
<!--                <span class="numAndPassVal" :class="this.healthCertificate.healthCertificateImage ? this.healthCertificatePass ? 'pass' : 'notPass' : ''">{{this.healthCertificate.healthCertificateImage ? this.healthCertificatePass ? '过期' : '未过期' : '未录入'}}</span>-->
                <span class="numAndPassVal" :class="this.healthCertificate.healthCertificateImage ? this.healthCertificatePass ? 'pass' : 'notPass' : ''">{{this.healthCertificatePass ? '过期' : '未过期'}}</span>
            </div>
            <img v-if="this.healthCertificate.healthCertificateImage && this.healthCertificate.healthCertificateImage !== 'http://m.wisevege.com/foodImg/zanwu.png'"
                 :src="this.healthCertificate.healthCertificateImage" alt="">
<!--            <span v-if="!this.healthCertificate.healthCertificateImage || this.healthCertificate.healthCertificateImage === 'http://m.wisevege.com/foodImg/zanwu.png'" class="noImg">暂无</span>-->
        </li>
<!--        <li v-if="this.storeInfo.businessLicense">-->
        <li>
            <div class="certificateInfo">
                <p class="certificateName">经营执照</p>
            </div>
            <img v-if="this.storeInfo.businessLicense && (this.storeInfo.businessLicense !== 'http://m.wisevege.com/foodImg/zanwu.png')" :src="storeInfo.businessLicense" alt="">
            <span v-if="!this.storeInfo.businessLicense || this.storeInfo.businessLicense === 'http://m.wisevege.com/foodImg/zanwu.png'" class="noImg">暂无</span>
        </li>
        <li v-if="!this.storeInfo.foodLicense || this.storeInfo.foodLicense !== 'http://m.wisevege.com/foodImg/zanwu.png'">
            <div class="certificateInfo">
                <p class="certificateName">食品经营许可证</p>
            </div>
            <img v-if="this.storeInfo.foodLicense && this.storeInfo.foodLicense !== 'http://m.wisevege.com/foodImg/zanwu.png'" :src="storeInfo.foodLicense" alt="">
<!--            <span v-if="!this.storeInfo.foodLicense || this.storeInfo.foodLicense === 'http://m.wisevege.com/foodImg/zanwu.png'" class="noImg">暂无</span>-->
        </li>
        <li>
            <div class="certificateInfo food-safety">
                <p class="certificateName">
                    <span>食品安全承诺书</span>
                    <span class="history-food-safety" @click="goToPage('FoodSafetyHistory', '')">查看历史记录</span>
                </p>
            </div>
            <span v-if="!this.storeInfo.foodSafetyImgs" class="noImg">未上传</span>
<!--            <img v-if="this.storeInfo.foodSafetyImgs && this.storeInfo.foodSafetyImgs !== 'http://m.wisevege.com/foodImg/zanwu.png'" :src="storeInfo.foodSafetyImgs" alt="">-->
            <div v-else>
                <img v-for="(item, index) in JSON.parse(this.storeInfo.foodSafetyImgs)" :key="index" :src="item" />
            </div>
        </li>
    </ul>
</template>

<script>
    import headerBox from '../components/headerBox';
    import MarketInfo from "../components/marketInfo";
    import axios from "axios";
    import globalFunctions from "../assets/js/globalFunctions";

    export default {
        name: "Certificate",
        components: {
            MarketInfo,
            headerBox,
        },
        data() {
            return {
                delivery: ['无'],
                deliveryOptions: ['有', '无'],
                storeInfo: {},
                healthCertificate: {},
                healthCertificatePass: false
            }
        },
        methods: {
            goToPage(url, param) {
                globalFunctions.goToPage(url, param, this.$store)
            },
        },
        mounted() {
            this.storeInfo = this.$store.state.storeInfo;
            if (this.$store.state.healthCertificateState){
                this.healthCertificate = this.$store.state.healthCertificateState.certificate;
                this.healthCertificatePass = this.healthCertificate.pass;
            }
        },
    }
</script>

<style scoped lang="scss">
    .marketInfo{
        width: 100%;
        margin: 50px auto 0;
        padding: 25px;
        box-sizing: border-box;
        background: #fff;
    }
    .certificateBox{
        background: #F1F1F1;
        padding: 25px;
        list-style: none;
        margin: 0;
        li{
            padding: 30px;
            background: #fff;
            border-radius: 10px;
            .certificateInfo{
                margin-bottom: 20px;
                text-align: left;
                display: flex;
                justify-content: space-between;
                p{
                    margin: 0;
                }
                .certificateName{
                    font-size: 32px/*px*/;
                    color: #333;
                }
                .numAndPassTitle, .numAndPassVal{
                    font-size: 28px/*px*/;
                }
                .numAndPassVal{
                    color: #B2B2B2;
                }
                .pass{
                    color: #FA4459;
                }
                .notPass{
                    color: #00CF3F;
                }

                &.food-safety{
                    .certificateName{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .history-food-safety{
                            font-size: 24px/*px*/;
                            color: #4285f4;
                        }
                    }
                }
            }
            img{
                width: 100%;
                border-radius: 10px;
                border: 1px/*no*/ solid #eee;
            }
            .noImg{
                display: inline-block;
                font-size: 28px;
                color: #999;
            }
        }
        li{
            margin-bottom: 25px;
        }
        li:last-of-type{
            margin-bottom: 0;
        }
    }
</style>
