<template>
    <header-box :storeInfo="storeInfo"></header-box>
    <ul class="marketDynamicsBox">
        <li>
            <div>
                <p>广安市副市长王瑛调研益民菜市中铁西城店</p>
                <p>2021-06-28</p>
            </div>
            <i class="el-icon-arrow-right"></i>
        </li>
        <li>
            <div>
                <p>陈建辉到益民菜市中铁西城店调研督导疫情 防控和保障民生服务情况</p>
                <p>2020-02-12</p>
            </div>
            <i class="el-icon-arrow-right"></i>
        </li>
        <li>
            <div>
                <p>成都益民菜市三家店开业 服务周边数万居民</p>
                <p>2015-09-21</p>
            </div>
            <i class="el-icon-arrow-right"></i>
        </li>
        <li>
            <div>
                <p>成都益民菜市簇桥店、中铁西城店、万家湾店三家店开业</p>
                <p>2015-09-21</p>
            </div>
            <i class="el-icon-arrow-right"></i>
        </li>
    </ul>
</template>

<script>
    import headerBox from "../components/headerBox";

    export default {
        name: "MarketDynamics",
        components: {
            headerBox,
        },
        data() {
            return {
                storeInfo: []
            }
        },
        mounted() {
            this.storeInfo = this.$store.state.storeInfo;
        }
    }
</script>

<style scoped lang="scss">
    .marketDynamicsBox{
        list-style: none;
        background: #F1F1F1;
        min-height: calc(100vh - 160px);
        padding: 30px;
        li{
            margin: 0 auto 30px;
            background: #fff;
            border-radius: 10px;
            padding: 20px;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: space-between;
            div{
                width: 90%;
                p{
                    margin: 0;
                    &:first-of-type{
                        font-size: 28px;
                        color: #666;
                        margin-top: 10px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    &:last-of-type{
                        color: #ccc;
                        margin: 10px 0;
                    }
                }
            }
        }
    }
</style>
