<template>
    <headerBox :storeInfo="storeInfo"></headerBox>
    <el-empty description="功能暂未开放"></el-empty>
</template>

<script>
    import headerBox from "../components/headerBox";
    export default {
        name: "NotYetOpen",
        components: {
            headerBox
        },
        mounted() {
            this.storeInfo = this.$store.state.storeInfo;
        },
        data() {
            return {
                storeInfo: {}
            }
        }
    }
</script>

<style scoped lang="scss">
    .el-empty{
        background: #fff;
        padding: 300px 0;
    }
</style>
