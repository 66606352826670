<template>
    <header-box :storeInfo="storeInfo"></header-box>
    <ul class="articleList">
        <li>
            <img src="http://www.foodu14.com/skin/default/img/cyaq.jpg" alt="">
            <div class="introAndGo">
                <span>【餐饮安全怎么做】餐饮服务从业人员的健康要求</span>
                <i class="el-icon-arrow-right"></i>
            </div>
        </li>
        <li>
            <img src="http://www.foodu14.com/skin/default/img/spaqf.jpg" alt="">
            <div class="introAndGo">
                <span>《食品安全法》</span>
                <i class="el-icon-arrow-right"></i>
            </div>
        </li>
    </ul>
</template>

<script>
    import headerBox from '../components/headerBox';

    export default {
        name: "PopularScience",
        components: {
            headerBox,
        },
        data() {
            return {
                storeInfo: []
            }
        },
        mounted() {
            this.storeInfo = this.$store.state.storeInfo;
        }
    }
</script>

<style scoped lang="scss">
    .articleList{
        min-height: calc(100vh - 160px);
        list-style: none;
        padding: 30px;
        background: #F1F1F1;
        li{
            border-radius: 10px;
            overflow: hidden;
            background: #fff;
            margin-bottom: 30px;
            img{
                width: 100%;
            }
            .introAndGo{
                padding: 20px;
                font-size: 28px;
                color: #666;
                display: flex;
                justify-content: space-between;
                align-items: center;
                span{
                    width: 90%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    text-align: left;
                }
            }
        }
    }
</style>
