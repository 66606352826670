<template>
    <header-box :storeInfo="storeInfo"></header-box>
    <ul class="optionsTab">
        <li class="optionLi" style="background: #E4F8F1;" @click="goToPage('Certificate', '')">
            <img src="../assets/img/组24.png" alt="">
            <span>主体<br/>公示</span>
        </li>
        <li class="optionLi" style="background: #FFF8E9;" @click="goToPage('TraceabilityList', '')">
            <img src="../assets/img/组22.png" alt="">
            <span>溯源<br/>公示</span>
        </li>
        <li class="optionLi" style="background: #E8FAFF;" @click="goToPage('MonitoringPublicity', '')">
            <img src="../assets/img/组28.png" alt="">
            <span>检测<br/>公示</span>
        </li>
        <!--        <li class="optionLi" style="background: #FFF6F6;" @click="goToPage('NotYetOpen', '')">-->
        <!--            <img src="../assets/img/组29.png" alt="">-->
        <!--            <span>监管<br/>公示</span>-->
        <!--        </li>-->
        <!--        <li class="optionLi" style="background: #F5F5FF;" @click="goToPage('NotYetOpen', '')">-->
        <!--            <img src="../assets/img/组26.png" alt="">-->
        <!--            <span>科普<br/>宣传</span>-->
        <!--        </li>-->
        <!--        <li class="optionLi" style="background: #FFFCEB;" @click="goToPage('NotYetOpen', '')">-->
        <!--            <img src="../assets/img/路径56.png" alt="">-->
        <!--            <span>市场<br/>动态</span>-->
        <!--        </li>-->
    </ul>
    <market-info :storeInfo="storeInfo"></market-info>
</template>

<style scoped lang="scss">
    .optionsTab {
        width: 700px;
        height: 150px;
        background: #ffffff;
        border-radius: 30px;
        box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.04);
        margin: 0 auto;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-content: center;

        .optionLi {
            width: 30%;
            height: 80%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            border-radius: 20px;
            margin: 10px 0;

            img {
                height: 40%;
            }

            span {
                font-size: 30px /*px*/
            ;
                color: #666;
            }
        }
    }
</style>

<script>
    // @ is an alias to /src
    // import HelloWorld from '@/components/HelloWorld.vue'
    import headerBox from '../components/headerBox';
    import marketInfo from "../components/marketInfo";
    import axios from 'axios';
    import globalFunctions from '../assets/js/globalFunctions'

    export default {
        name: 'Home',
        components: {
            // HelloWorld
            headerBox,
            marketInfo
        },
        methods: {
            goToPage(url, param) {
                globalFunctions.goToPage(url, param, this.$store)
            },
            //utf-8编码转换为utf-16编码，用于通过Url获取商家信息时进行转换
            utf8to16(str) {
                var out, i, len, c;
                var char2, char3;
                out = "";
                len = str.length;
                i = 0;
                while (i < len) {
                    c = str.charCodeAt(i++);
                    switch (c >> 4) {
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                            // 0xxxxxxx
                            out += str.charAt(i - 1);
                            break;
                        case 12:
                        case 13:
                            // 110x xxxx 10xx xxxx
                            char2 = str.charCodeAt(i++);
                            out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                            break;
                        case 14:
                            // 1110 xxxx 10xx xxxx 10xx xxxx
                            char2 = str.charCodeAt(i++);
                            char3 = str.charCodeAt(i++);
                            out += String.fromCharCode(((c & 0x0F) << 12) |
                                ((char2 & 0x3F) << 6) |
                                ((char3 & 0x3F) << 0));
                            break;
                    }
                }
                return out;
            }
        },
        mounted() {
            //通过解析Url获取商家信息，格式为——姓名_电话号码
            // console.log(window.location.href.match(/name=(\S*)&storeId=/));
            console.log(window.location.href.split('&storeId=')[1]);
            let storeId, storeName;
            if (window.location.href.split('&storeId=')[1]){
                storeName = window.location.href.match(/name=(\S*)&storeId=/)[1];
                storeId = window.location.href.split('storeId=')[1];
            }else {
                storeName = window.location.href.split('?name=')[1];
            }
            this.$store.state.storeName = this.utf8to16(atob(storeName));
            storeName = storeName.replace(/_/g, '/');
            storeName = storeName.replace(/-/g, '+');
            let requestData;
            if (window.location.href.split('&storeId=')[1]){
                requestData = `name=${this.$store.state.storeName.split('_')[0]}&phone=${this.$store.state.storeName.split('_')[1]}&storeId=${storeId}`;
            }else {
                requestData = `name=${this.$store.state.storeName.split('_')[0]}&phone=${this.$store.state.storeName.split('_')[1]}`
            }
            // console.log(storeName, storeId)
            //发送请求获取对应主体信息
            axios({
                url: '/api/findByPhone',
                method: 'post',
                contentType: 'application/x-www-form-urlencoded',
                data: requestData
            }).then(res => {
                this.storeInfo = res.data.data;
                console.log(this.storeInfo)
                //主体信息存入vuex以备用
                this.$store.state.storeInfo = this.storeInfo;
                axios({
                    url: `/api/health/queryNewestHealthCertificate?storeId=${this.storeInfo.id}`,
                    method: 'get',
                }).then(res => {
                    this.healthCertificateState = JSON.parse(res.data.data);
                    this.$store.state.healthCertificateState = this.healthCertificateState;
                    // this.healthCertificatePass = JSON.parse(res.data.data).pass;
                })
            })
        },
        data() {
            return {
                storeInfo: [],
                healthCertificateState: {}
            }
        }
    }
</script>
